<template>
  <div class="message">
    <Robot v-if="setup.locale.error" />
    <Warning v-else-if="setup.locale.warning" />
    <Logo v-else />

    <div>
      <h1>{{ setup.locale.message.title }}</h1>
      <section v-html="setup.locale.message.description"></section>
    </div>

    <Link v-if="setup.locale.message.link" :href="setup.locale.message.href" :as="'button'"
      :data="{ ...setup.locale.message.data }"
    >
      {{ setup.locale.message.link.text }}
    </Link>
    <ButtonCloseApp v-else />
  </div>
</template>

<script setup lang="ts">
  import { storeToRefs } from 'pinia'
  import { useControllerStore } from '@/stores/controller'

  const { setup } = storeToRefs(useControllerStore())
</script>

<style scoped lang="scss">
  [data-theme="light"] .warning {
    --message-text-color: #8d8d8d;
  }

  .message {
    --message-text-color: #7A7A7B;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: end;
    gap: 80px;
    height: calc(100dvh - 2rem);
    text-align: center;
    padding-bottom: 70px;
    img {
      height: 200px;
      width: auto;
    }
    h1 {
      margin-bottom: 0;
      font-size: 28px;
      text-align: center;
    }
    h4 {
      margin-bottom: 0;
      font-size: 28px;
    }
    section {
      margin-top: var(--space);
      font-size: 18px;
      font-weight: 600;
      line-height: 24.51px;
      text-align: center;
      color: var(--message-text-color);
    }
    div {
      display: flex;
      flex-direction: column;
      gap: var(--space);
      text-align: start;
    }
    button {
      margin-top: var(--space);
    }
  }
</style>
