<template>
  <div class="warning">
    <svg width="120" height="98" viewBox="0 0 120 98" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M57.6953 20.6654C60.3568 16.4501 66.4674 16.4501 69.1289 20.6654L105.344 78.0236C108.211 82.5646 104.971 88.5039 99.6269 88.5039H27.1973C21.8532 88.5039 18.6134 82.5646 21.4805 78.0236L57.6953 20.6654Z"/>
      <path d="M101.645 88.5039H18.1769C15.6695 88.5039 13.4526 87.1883 12.258 84.9885C11.0527 82.7887 11.1388 80.2114 12.4948 78.0978L54.2178 12.6311C55.4662 10.6793 57.597 9.50391 59.9107 9.50391C62.2245 9.50391 64.3553 10.6685 65.6037 12.6311L107.337 78.0978C108.683 80.2114 108.769 82.7887 107.574 84.9885C106.369 87.1883 104.152 88.5039 101.645 88.5039ZM59.9107 13.2889C58.8776 13.2889 57.9629 13.7957 57.4033 14.6692L15.6695 80.1359C15.0776 81.0633 15.0345 82.2063 15.5726 83.1769C16.1107 84.1474 17.0793 84.7297 18.1769 84.7297H101.645C102.753 84.7297 103.722 84.1474 104.249 83.1769C104.776 82.2063 104.744 81.0741 104.152 80.1359L62.4182 14.6692C61.8586 13.7957 60.9439 13.2889 59.9107 13.2889Z"/>
      <path d="M59.9121 76.5039C62.3974 76.5039 64.4121 74.4892 64.4121 72.0039C64.4121 69.5186 62.3974 67.5039 59.9121 67.5039C57.4268 67.5039 55.4121 69.5186 55.4121 72.0039C55.4121 74.4892 57.4268 76.5039 59.9121 76.5039Z"/>
      <path d="M57.8585 61.6961L56.4153 30.8689C56.3332 29.0854 57.8351 27.583 59.7593 27.5073C61.8009 27.4208 63.5023 28.9882 63.4084 30.8689L61.9652 61.6961C61.7775 64.1065 58.0697 64.1065 57.8585 61.6961Z"/>
    </svg>
  </div>
</template>

<script setup lang="ts"></script>

<style scoped lang="scss">
  [data-theme="light"] .warning {
    --svg-main-color: var(--primary-color);
    --svg-second-color: var(--text-color);
  }

  .warning {
    --svg-main-color: #fff;
    --svg-second-color: #7A7A7B;

    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    svg {
      width: 120px;
      height: 98px;
      animation: 1s ease 0s infinite normal both running svg-change;

      path:nth-child(1) {
        fill: var(--svg-main-color);
        transform-origin: 50% 50%;
        animation: 1s ease 1s infinite normal both running svg-path-change;
      }
      path:nth-child(2) {
        fill: var(--svg-second-color);
        transform-origin: 50% 50%;
        animation: 1s ease 1s infinite normal both running svg-border-path-change;
      }
      path:nth-child(3) {
        fill: var(--svg-second-color);

      }
      path:nth-child(4) {
        fill: var(--svg-second-color);
      }
    }
  }

  @keyframes svg-change {
    0% {
      transform: scale(1);
    }
    5% {
      transform: scale(1.2);
    }
    50% {
      transform: scale(1);
    }
    100% {
      transform: scale(1);
    }
  }

  @keyframes svg-path-change {
    50% {
      transform: rotate(0deg);
    }
    60% {
      transform: rotate(-10deg);
    }
    70% {
      transform: rotate(10deg);
    }
    80% {
      transform: rotate(-10deg);
    }
    90% {
      transform: rotate(0deg);
    }
  }

  @keyframes svg-border-path-change {
    50% {
      transform: rotate(0deg);
    }
    50% {
      transform: rotate(-10deg);
    }
    60% {
      transform: rotate(10deg);
    }
    70% {
      transform: rotate(-10deg);
    }
    80% {
      transform: rotate(0deg);
    }
  }
</style>
