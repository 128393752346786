<template>
  <BaseNotification />
</template>

<script lang="ts" setup>
  import { onMounted } from 'vue'
  import { storeToRefs } from 'pinia'
  import { useControllerStore } from '@/stores/controller'
  import { setThemeType, setCustomColorTheme } from '@/composables/templateSettings';

  const { setup } = storeToRefs(useControllerStore())

  const props = defineProps<{
    controller: string;
    locale: object;
    base_locale: object;
  }>()

  Object.assign(setup.value, props)
  setup.value.locale.error = true

  onMounted(() => {
    setThemeType();
    setCustomColorTheme();
  });
</script>

<style scoped lang="scss"></style>
