<template>
  <div class="robot">
    <svg width="109" height="120" viewBox="0 0 109 120" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="10.6875" y="41.0215" width="87.2789" height="77.479" rx="18.5" fill="#5061FF" stroke="#161698" stroke-width="3" stroke-linejoin="round"/>
      <line x1="20.5" y1="29" x2="20.5" y2="44" stroke="#161698" stroke-width="3"/>
      <line x1="89.4727" y1="29.452" x2="89.5025" y2="43.9976" stroke="#161698" stroke-width="3"/>
      <circle cx="20.8798" cy="24.8798" r="4.37992" transform="rotate(180 20.8798 24.8798)" fill="#5061FF" stroke="#161698" stroke-width="3"/>
      <circle cx="89.2783" cy="24.8798" r="4.37992" transform="rotate(-180 89.2783 24.8798)" fill="#5061FF" stroke="#161698" stroke-width="3"/>
      <path class="open-eye" d="M67.5 70C67.5 66.9624 69.9624 64.5 73 64.5C76.0376 64.5 78.5 66.9624 78.5 70C78.5 73.0376 76.0376 75.5 73 75.5C69.9624 75.5 67.5 73.0376 67.5 70Z" fill="white" stroke="#161698" stroke-width="3"/>
      <path class="open-eye" d="M30.5 70C30.5 66.9624 32.9624 64.5 36 64.5C39.0376 64.5 41.5 66.9624 41.5 70C41.5 73.0376 39.0376 75.5 36 75.5C32.9624 75.5 30.5 73.0376 30.5 70Z" fill="white" stroke="#161698" stroke-width="3"/>
      <rect x="22.4473" y="90.6738" width="64.4125" height="11.4932" rx="5.74661" fill="#5061FF" stroke="#161698" stroke-width="3" stroke-linejoin="round"/>
      <rect x="22.4473" y="90.6738" width="64.4125" height="11.4932" rx="5.74661" fill="white" stroke="#161698" stroke-width="3" stroke-linejoin="round"/>
      <path d="M81 40.5C81.8284 40.5 82.5 39.8284 82.5 39V38C82.5 34.9624 80.0376 32.5 77 32.5H33C29.9624 32.5 27.5 34.9624 27.5 38V39C27.5 39.8284 28.1716 40.5 29 40.5H81Z" fill="#5061FF" stroke="#161698" stroke-width="3" stroke-linejoin="round"/>
      <path d="M11.0733 56.8942C11.0733 56.0658 10.4017 55.3942 9.57327 55.3942L9 55.3942C5.96243 55.3942 3.5 57.8567 3.5 60.8942L3.5 97.3203C3.5 100.358 5.96244 102.82 9 102.82L9.57328 102.82C10.4017 102.82 11.0733 102.149 11.0733 101.32L11.0733 56.8942Z" fill="#5061FF" stroke="#161698" stroke-width="3" stroke-linejoin="round"/>
      <path d="M97.5791 101.321C97.5791 102.149 98.2506 102.821 99.0791 102.821L99.6523 102.821C102.69 102.821 105.152 100.358 105.152 97.3206L105.152 60.8945C105.152 57.857 102.69 55.3945 99.6523 55.3945L99.0791 55.3945C98.2506 55.3945 97.5791 56.0661 97.5791 56.8945L97.5791 101.321Z" fill="#5061FF" stroke="#161698" stroke-width="3" stroke-linejoin="round"/>
      <line x1="38.5117" y1="89.5586" x2="38.5117" y2="101.318" stroke="#161698" stroke-width="3"/>
      <line x1="70.5254" y1="89.5586" x2="70.5254" y2="101.318" stroke="#161698" stroke-width="3"/>
      <line x1="54.8457" y1="89.5586" x2="54.8457" y2="101.318" stroke="#161698" stroke-width="3"/>
      <path class="close-eye" fill-rule="evenodd" clip-rule="evenodd" d="M45 70C45 71.1046 44.3081 72 43.4545 72L29.5455 72C28.6919 72 28 71.1046 28 70C28 68.8954 28.6919 68 29.5455 68L43.4545 68C44.3081 68 45 68.8954 45 70Z" fill="#161698"/>
      <path class="close-eye" fill-rule="evenodd" clip-rule="evenodd" d="M81 70C81 71.1046 80.3081 72 79.4545 72L65.5455 72C64.6919 72 64 71.1046 64 70C64 68.8954 64.6919 68 65.5455 68L79.4545 68C80.3081 68 81 68.8954 81 70Z" fill="#161698"/>
      <path class="died-eye" d="M65 62.0879L79.4733 77.0879" stroke="#161698" stroke-width="3" stroke-linecap="round"/>
      <path class="died-eye" d="M80 62.0879L65.5267 77.0879" stroke="#161698" stroke-width="3" stroke-linecap="round"/>
      <path class="died-eye" d="M29 62L43.4733 77" stroke="#161698" stroke-width="3" stroke-linecap="round"/>
      <path class="died-eye" d="M44 62L29.5267 77" stroke="#161698" stroke-width="3" stroke-linecap="round"/>
      <path class="died-half-eye" d="M67.0859 64.1738L77.6997 75.1738" stroke="#161698" stroke-width="3" stroke-linecap="round"/>
      <path class="died-half-eye" d="M78.0859 64.1738L67.4722 75.1738" stroke="#161698" stroke-width="3" stroke-linecap="round"/>
      <path class="died-half-eye" d="M31 64L41.6138 75" stroke="#161698" stroke-width="3" stroke-linecap="round"/>
      <path class="died-half-eye" d="M42 64L31.3862 75" stroke="#161698" stroke-width="3" stroke-linecap="round"/>
      <path class="full-signal" fill-rule="evenodd" clip-rule="evenodd" d="M29.3282 17.131C28.7425 16.5452 28.6595 15.6784 29.143 15.1948L36.5845 7.75339C37.068 7.26988 37.9348 7.3528 38.5206 7.93858C39.1064 8.52437 39.1893 9.3912 38.7058 9.87471L31.2644 17.3162C30.7809 17.7997 29.914 17.7168 29.3282 17.131Z" fill="#161698"/>
      <path class="full-signal" fill-rule="evenodd" clip-rule="evenodd" d="M10.254 17.8593C9.66823 18.445 8.8014 18.528 8.31789 18.0445L0.876437 10.603C0.392931 10.1195 0.475845 9.25266 1.06163 8.66687C1.64742 8.08109 2.51425 7.99817 2.99776 8.48168L10.4392 15.9231C10.9227 16.4066 10.8398 17.2735 10.254 17.8593Z" fill="#161698"/>
      <path class="full-signal" fill-rule="evenodd" clip-rule="evenodd" d="M19.8437 13C19.0153 13 18.3438 12.4457 18.3438 11.7619L18.3438 1.2381C18.3438 0.554315 19.0153 3.7699e-07 19.8438 3.86869e-07C20.6722 3.96747e-07 21.3437 0.554315 21.3437 1.2381L21.3437 11.7619C21.3437 12.4457 20.6722 13 19.8437 13Z" fill="#161698"/>
      <path class="full-signal" fill-rule="evenodd" clip-rule="evenodd" d="M98.3282 17.131C97.7425 16.5452 97.6595 15.6784 98.143 15.1948L105.584 7.75339C106.068 7.26988 106.935 7.3528 107.521 7.93858C108.106 8.52437 108.189 9.3912 107.706 9.87471L100.264 17.3162C99.7809 17.7997 98.914 17.7168 98.3282 17.131Z" fill="#161698"/>
      <path class="full-signal" fill-rule="evenodd" clip-rule="evenodd" d="M79.254 17.8593C78.6682 18.445 77.8014 18.528 77.3179 18.0445L69.8764 10.603C69.3929 10.1195 69.4758 9.25266 70.0616 8.66687C70.6474 8.08109 71.5143 7.99817 71.9978 8.48168L79.4392 15.9231C79.9227 16.4066 79.8398 17.2735 79.254 17.8593Z" fill="#161698"/>
      <path class="full-signal" fill-rule="evenodd" clip-rule="evenodd" d="M88.8437 13C88.0153 13 87.3438 12.4457 87.3438 11.7619L87.3438 1.2381C87.3438 0.554315 88.0153 3.7699e-07 88.8438 3.86869e-07C89.6722 3.96747e-07 90.3437 0.554315 90.3437 1.2381L90.3437 11.7619C90.3437 12.4457 89.6722 13 88.8437 13Z" fill="#161698"/>
      <path class="half-signal" fill-rule="evenodd" clip-rule="evenodd" d="M31.4415 15.0177C30.8557 14.4319 30.6827 13.6552 31.0551 13.2828L36.7858 7.55212C37.1581 7.17977 37.9348 7.3528 38.5206 7.93858C39.1064 8.52437 39.2794 9.30109 38.9071 9.67344L33.1764 15.4042C32.804 15.7765 32.0273 15.6035 31.4415 15.0177Z" fill="#161698"/>
      <path class="half-signal" fill-rule="evenodd" clip-rule="evenodd" d="M8.12902 15.7343C7.54323 16.32 6.76699 16.4936 6.39523 16.1218L0.673669 10.4002C0.301912 10.0285 0.475417 9.25223 1.0612 8.66645C1.64699 8.08066 2.42323 7.90715 2.79499 8.27891L8.51655 14.0005C8.88831 14.3722 8.71481 15.1485 8.12902 15.7343Z" fill="#161698"/>
      <path class="half-signal" fill-rule="evenodd" clip-rule="evenodd" d="M19.8437 10C19.0153 10 18.3438 9.5736 18.3438 9.04762L18.3438 0.952381C18.3438 0.426396 19.0153 -3.34009e-07 19.8438 -3.2413e-07C20.6722 -3.14251e-07 21.3437 0.426396 21.3437 0.952381L21.3437 9.04762C21.3437 9.5736 20.6722 10 19.8437 10Z" fill="#161698"/>
      <path class="half-signal" fill-rule="evenodd" clip-rule="evenodd" d="M100.442 15.0177C99.8557 14.4319 99.6827 13.6552 100.055 13.2828L105.786 7.55212C106.158 7.17977 106.935 7.3528 107.521 7.93858C108.106 8.52437 108.279 9.30109 107.907 9.67344L102.176 15.4042C101.804 15.7765 101.027 15.6035 100.442 15.0177Z" fill="#161698"/>
      <path class="half-signal" fill-rule="evenodd" clip-rule="evenodd" d="M77.129 15.7343C76.5432 16.32 75.767 16.4936 75.3952 16.1218L69.6737 10.4002C69.3019 10.0285 69.4754 9.25223 70.0612 8.66645C70.647 8.08066 71.4232 7.90715 71.795 8.27891L77.5166 14.0005C77.8883 14.3722 77.7148 15.1485 77.129 15.7343Z" fill="#161698"/>
      <path class="half-signal" fill-rule="evenodd" clip-rule="evenodd" d="M88.8437 10C88.0153 10 87.3438 9.5736 87.3438 9.04762L87.3438 0.952381C87.3438 0.426396 88.0153 -3.34009e-07 88.8438 -3.2413e-07C89.6722 -3.14251e-07 90.3437 0.426396 90.3437 0.952381L90.3437 9.04762C90.3437 9.5736 89.6722 10 88.8437 10Z" fill="#161698"/>
      <path class="low-signal" fill-rule="evenodd" clip-rule="evenodd" d="M103.772 11.6896C103.186 11.1038 102.871 10.469 103.068 10.2718L106.103 7.23665C106.3 7.03944 106.935 7.35445 107.521 7.94023C108.107 8.52602 108.422 9.16076 108.225 9.35797L105.189 12.3931C104.992 12.5904 104.357 12.2754 103.772 11.6896Z" fill="#161698"/>
      <path class="low-signal" fill-rule="evenodd" clip-rule="evenodd" d="M73.6388 12.246C73.053 12.8318 72.4255 13.154 72.2373 12.9658L69.3402 10.0687C69.1519 9.88044 69.4742 9.25297 70.06 8.66718C70.6458 8.08139 71.2732 7.75912 71.4615 7.94736L74.3586 10.8445C74.5468 11.0327 74.2246 11.6602 73.6388 12.246Z" fill="#161698"/>
      <path class="low-signal" fill-rule="evenodd" clip-rule="evenodd" d="M88.8437 5C88.0153 5 87.3438 4.7868 87.3438 4.52381L87.3438 0.47619C87.3438 0.213198 88.0153 -1.63e-07 88.8437 -1.53121e-07C89.6722 -1.43242e-07 90.3437 0.213198 90.3437 0.47619L90.3437 4.52381C90.3437 4.7868 89.6722 5 88.8437 5Z" fill="#161698"/>
      <path class="low-signal" fill-rule="evenodd" clip-rule="evenodd" d="M34.7716 11.6896C34.1858 11.1038 33.8708 10.469 34.068 10.2718L37.1032 7.23665C37.3004 7.03944 37.9351 7.35445 38.5209 7.94023C39.1067 8.52602 39.4217 9.16076 39.2245 9.35797L36.1893 12.3931C35.9921 12.5904 35.3574 12.2754 34.7716 11.6896Z" fill="#161698"/>
      <path class="low-signal" fill-rule="evenodd" clip-rule="evenodd" d="M4.63879 12.246C4.053 12.8318 3.42553 13.154 3.23729 12.9658L0.340161 10.0687C0.151921 9.88044 0.474197 9.25297 1.05998 8.66718C1.64577 8.08139 2.27324 7.75912 2.46148 7.94736L5.35861 10.8445C5.54685 11.0327 5.22457 11.6602 4.63879 12.246Z" fill="#161698"/>
      <path class="low-signal" fill-rule="evenodd" clip-rule="evenodd" d="M19.8437 5C19.0153 5 18.3438 4.7868 18.3438 4.52381L18.3438 0.47619C18.3438 0.213198 19.0153 -1.63e-07 19.8437 -1.53121e-07C20.6722 -1.43242e-07 21.3437 0.213198 21.3437 0.47619L21.3437 4.52381C21.3437 4.7868 20.6722 5 19.8437 5Z" fill="#161698"/>
    </svg>
  </div>
</template>

<script setup lang="ts"></script>

<style scoped lang="scss">
  .robot {
    --duration: 1s;

    svg {
      width: 110px;
      height: 120px;
      animation: var(--duration) ease 0s infinite normal both running svg-change;

      .close-eye, .died-half-eye, died-eye .half-signal, .low-signal {
        visibility: hidden;
      }

      .open-eye {
        animation: var(--duration) ease 0s infinite normal both running open-eye-change;
      }

      .close-eye {
        animation: var(--duration) ease 0s infinite normal both running close-eye-change;
      }

      .died-eye {
        animation: var(--duration) ease 0s infinite normal both running died-eye-change;
      }

      .died-half-eye {
        animation: var(--duration) ease 0s infinite normal both running died-half-eye-change;
      }

      .full-signal {
        animation: var(--duration) ease 0s infinite normal both running full-signal-change;
      }

      .half-signal {
        animation: var(--duration) ease 0s infinite normal both running half-signal-change;
      }

      .low-signal {
        animation: var(--duration) ease 0s infinite normal both running low-signal-change;
      }
    }
  }

  @keyframes open-eye-change {
    10% {
      visibility: hidden;
    }
    100% {
      visibility: hidden;
    }
  }

  @keyframes close-eye-change {
    0% {
      visibility: hidden;
    }
    9% {
      visibility: hidden;
    }
    10% {
      visibility: visible;
    }
    20% {
      visibility: hidden;
    }
    100% {
      visibility: hidden;
    }
  }

  @keyframes died-eye-change {
    0% {
      visibility: hidden;
    }
    20% {
      visibility: hidden;
    }
    21% {
      visibility: visible;
    }
    40% {
      visibility: hidden;
    }
    60% {
      visibility: hidden;
    }
    61% {
      visibility: visible;
    }
    80% {
      visibility: hidden;
    }
    100% {
      visibility: hidden;
    }
  }

  @keyframes died-half-eye-change {
    0% {
      visibility: hidden;
    }
    40% {
      visibility: hidden;
    }
    41% {
      visibility: visible;
    }
    60% {
      visibility: hidden;
    }
    80% {
      visibility: hidden;
    }
    81% {
      visibility: visible;
    }
    100% {
      visibility: visible;
    }
  }

  @keyframes full-signal-change {
    29% {
      visibility: visible;
    }
    30% {
      visibility: hidden;
    }
    100% {
      visibility: hidden;
    }
  }

  @keyframes half-signal-change {
    29% {
      visibility: hidden;
    }
    30% {
      visibility: visible;
    }
    59% {
      visibility: visible;
    }
    60% {
      visibility: hidden;
    }
    100% {
      visibility: hidden;
    }
  }

  @keyframes low-signal-change {
    59% {
      visibility: hidden;
    }
    60% {
      visibility: visible;
    }
    100% {
      visibility: visible;
    }
  }

  @keyframes svg-change {
    0% {
      transform-origin: 50% 50%;
      transform: translateX(0);
    }
    19% {
      transform: translateX(0);
    }
    20% {
      transform: translateX(2px);
    }
    30% {
      transform: translateX(2px);
    }
    31% {
      transform: translateX(0);
    }
    59% {
      transform: translateX(0);
    }
    60% {
      transform: translateX(1px);
    }
    70% {
      transform: translateX(1px);
    }
    71% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(0);
    }
  }
</style>
